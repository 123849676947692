import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routesPc = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
  }
]

const routerYd = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/movem/index.vue'),
  }
]


var routes = []
let Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
if (navigator.userAgent.match(Adaptive)) {
  routes = routerYd
} else {
  routes = routesPc
}

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
