import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/fonts/fonts.css'
// 导入js文件 根据窗口调整html-font-size大小
import 'amfe-flexible';
import ElementUI from 'element-ui';

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
